
/* Переопределение material ui */
.MuiCardContent-root:last-child {
    padding-bottom: 12px !important;
}


#name-label.MuiFormLabel-root.Mui-focused {
    color: #bbb;
}

#login-label.MuiFormLabel-root.Mui-focused {
    color: #bbb;
}

#email-label.MuiFormLabel-root.Mui-focused {
    color: #bbb;
}

.MuiInput-underline.Mui-focused:after {
    transform: scaleX(0)!important;
}